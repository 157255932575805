<template>
  <el-dialog width="550px" :visible="userDialog" @close="close" :close-on-click-modal="false">
    <div slot="title">
      <span class="mr10 font14">{{ isEdit ? '编辑用户' : '新增用户' }}</span>
      <el-tag>可输入关键词回车搜索</el-tag>
    </div>
    <el-form label-width="80px" :model="userDetail" :rules="rules" ref="form">
      <el-form-item label="登录账号" prop="account">
        <el-input placeholder="请输入" v-model="userDetail.account" clearable @keyup.enter.native="getEmployeeInfo(userDetail.account)">
          <!-- <template slot="append" v-if="userDetail.tenantCode">-{{ userDetail.tenantCode }}</template> -->
        </el-input>
      </el-form-item>
      <el-form-item label="账号昵称" prop="name">
        <el-input placeholder="请输入" v-model="userDetail.name" clearable @keyup.enter.native="getEmployeeInfo(userDetail.name)"></el-input>
      </el-form-item>
      <el-form-item label="启用状态">
        <el-switch v-model="userDetail.useStatus" active-color="#13ce66"  inactive-color="#ff4949"> </el-switch>
      </el-form-item>
      <el-form-item label="手机号码" prop="phone">
        <el-input placeholder="请输入" v-model="userDetail.phone" clearable @keyup.enter.native="getEmployeeInfo(userDetail.phone)"></el-input>
      </el-form-item>
      <el-form-item label="邮箱" prop="email">
        <el-input placeholder="请输入" v-model="userDetail.email" clearable></el-input>
      </el-form-item>
      <el-form-item label="头像" prop="iconUrl">
        <image-upload :url.sync="userDetail.iconUrl" @success="handleAvatarSuccess" :params="{ businessType: 'system_icon' }" :fileSize="2" fileType="image" />
      </el-form-item>
      <el-form-item label="密码" prop="password" v-if="!isEdit">
        <el-input placeholder="请输入" v-model="userDetail.password" clearable></el-input>
      </el-form-item>
      
    </el-form>
    <div slot="footer">
      <el-button @click="close">取消</el-button>
      <el-button type="primary" @click="save">确定</el-button>
    </div>
  </el-dialog>
</template>
<script>
import api from '@/api/auth/user2'
import commonApi from '@/api/common/index'
import imageUpload from '@/components/imageUpload'
export default {
  props: {
    userDialog: {
      default: false,
      type: Boolean,
    },
    userDetail: {
      default: null,
      type: Object,
    },
  },
  components: { imageUpload },
  data() {
    const phoneRule = (rule, value, callback) => {
      if (value && !/^1[3456789]\d{9}$/.test(value)) {
        callback(new Error('请输入正确的手机号码'))
      } else {
        callback()
      }
    }
    return {
      // form: {},
      rules: {
        account: [{ required: true, message: '请输入登录账号', trigger: 'blur' }],
        name: [{ required: true, message: '请输入账号昵称', trigger: 'blur' }],
        password: [{ required: true, message: '请输入密码', trigger: 'blur' }],
        phone: [{ validator: phoneRule, trigger: 'blur' }],
      },
      isEdit: false,
      typeList: [],
    }
  },
  watch: {
    userDialog(val) {
      if (val) {
        this.isEdit = !_.isEmpty(this.userDetail.id)
      }else{
        this.$refs['form'].resetFields()
      }
    },
  },
  created() {},
  methods: {
    close() {
      this.$emit('update:userDialog', false)
    },
    save() {
      this.$refs['form'].validate((valid) => {
        if (!valid) return
        let handleType = this.isEdit ? 'modify' : 'add'
        api[handleType](this.userDetail).then(() => {
          this.$msg.success('操作成功')
          this.$emit('close')
        })
      })
    },
    handleAvatarSuccess(res, file) {
      const { ossUrl } = res.data
      this.$set(this.userDetail, 'iconUrl', ossUrl)
    },
    getEmployeeInfo(keyword) {
      if (!keyword) return
      commonApi.getEmployeeInfo({ keyword }).then((res) => {
        if (res.data && res.data.length) {
          const { accountName: name, cellphone: phone, email, jobId: jobNumber } = res.data[0]
          this.$set(this.userDetail, 'name', name)
          this.$set(this.userDetail, 'phone', phone)
          this.$set(this.userDetail, 'email', email)
          this.$set(this.userDetail, 'jobNumber', jobNumber)
          this.$set(this.userDetail, 'account', jobNumber)
          this.$refs['form'].clearValidate()
        }
      })
    },
  },
}
</script>
