<template>
  <el-dialog
    title="用户分配权限"
    width="500px"
    :visible="userAuthDialog"
    @close="close"
    :close-on-click-modal="false"
  >
    <el-tree
      :data="userAuthData"
      ref="tree"
      show-checkbox
      :default-expanded-keys="expandedKeys"
      node-key="id"
    >
      <span class="custom-tree-node" slot-scope="{ node, data }">
        <!-- <el-tag type="success">{{ data.name }}</el-tag> -->
        {{ data.name }}
        <el-tag size="mini">{{ data.code }}</el-tag>
      </span>
    </el-tree>

    <div slot="footer">
      <el-button @click="close">取消</el-button>
      <el-button type="primary" @click="save">确定</el-button>
    </div>
  </el-dialog>
</template>
<script>
import api from "@/api/auth/user2";
export default {
  props: {
    userAuthDialog: {
      default: false,
      type: Boolean,
    },
    userDetail: {
      default: null,
      type: Object,
    },
    rangeFlag: {
      require: true,
      type: String,
    },
  },
  data() {
    return {
      userAuthData: [],
      expandedKeys: [],
    };
  },
  watch: {
    userAuthDialog(val) {
      if (val && this.userDetail && this.userDetail.id) {
        this.getUserAuth();
      }
    },
  },
  methods: {
    getUserAuth() {
      api
        .getUserAuth(this.rangeFlag, { userId: this.userDetail.id })
        .then((res) => {
          this.userAuthData = res.data.baseDataTree;
          this.expandedKeys = res.data.checkedDataIds;
          this.$refs.tree.setCheckedKeys(res.data.checkedDataIds);
        });
    },

    close() {
      this.$emit("update:userAuthDialog", false);
    },
    save() {
      const params = {
        userId: this.userDetail.id,
        checkedDataIds: this.$refs.tree.getCheckedKeys(),
      };
      api.userAuth(this.rangeFlag, params).then(() => {
        this.$msg.success("操作成功");
        this.close();
      });
    },
  },
};
</script>
