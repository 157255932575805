<template>
  <div class="h100">
    <common-layout>
      <template #header>
        <el-form inline>
          <el-form-item label="昵称">
            <el-input
              placeholder="请输入"
              v-model="form.name"
              clearable
            ></el-input>
          </el-form-item>
          <el-form-item label="账号">
            <el-input
              placeholder="请输入"
              v-model="form.account"
              clearable
            ></el-input>
          </el-form-item>
          <el-form-item label="状态">
            <el-select v-model="form.useStatus" clearable>
              <el-option label="已启用" :value="true"></el-option>
              <el-option label="已禁用" :value="false"></el-option>
            </el-select>
          </el-form-item>
          <!-- <el-form-item label="系统">
            <el-select v-model="form.systemId" clearable>
              <el-option v-for="(item, index) in systemList" :key="index" :value="item.id" :label="item.systemName"></el-option>
            </el-select>
          </el-form-item> -->
          <el-form-item>
            <el-button type="primary" @click="handleQuery">查询</el-button>
            <el-button type="primary" plain @click="handleSave(true)"
              >新增</el-button
            >
          </el-form-item>
          <el-form-item>
            <el-tag type="info" size="mini"
              ><i class="el-icon-info"></i
              >建议：通过角色为用户赋权，而不是直接给用户赋权</el-tag
            >
          </el-form-item>
        </el-form>
      </template>
      <template #default>
        <el-table
          ref="table"
          :data="tableData"
          v-loading="tableLoading"
          border
          stripe
          height="100%"
        >
          <el-table-column
            prop="name"
            label="昵称"
            align="center"
            min-width="100"
          />
          <el-table-column
            prop="account"
            label="账号"
            align="center"
            min-width="100"
          />
          <!-- <el-table-column prop="userAccountTypeList" label="账号类型" align="center" min-width="180">
            <template slot-scope="scope">
              <div v-for="(item, index) in scope.row.userAccountTypeList" :key="index">
                <el-tag>
                  {{ `${item.systemName} &lt;${item.accountScopeName}&gt;` }}
                </el-tag>
              </div>
            </template>
          </el-table-column> -->
          <el-table-column
            prop="iconUrl"
            label="头像"
            align="center"
            min-width="100"
          >
            <template slot-scope="scope">
              <el-image
                v-if="scope.row.iconUrl"
                :src="scope.row.iconUrl"
                :preview-src-list="[scope.row.iconUrl]"
                style="max-width: 70px"
              />
            </template>
          </el-table-column>
          <el-table-column
            prop="phone"
            label="手机号码"
            align="center"
            min-width="100"
          />
          <el-table-column
            prop="email"
            label="邮箱"
            align="center"
            min-width="180"
          />

          <el-table-column
            prop="useStatus"
            label="状态"
            align="center"
            min-width="100"
          >
            <template slot-scope="scope">
              <span
                :class="
                  scope.row.useStatus ? 'enable-status' : 'disable-status'
                "
                >{{ scope.row.useStatus ? "已启用" : "已禁用" }}</span
              >
            </template>
          </el-table-column>
          <el-table-column
            prop="createUser"
            label="创建人"
            align="center"
            min-width="100"
          />
          <el-table-column
            prop="createTime"
            label="创建时间"
            align="center"
            min-width="150"
          />
          <el-table-column
            prop="updateUser"
            label="更新人"
            align="center"
            min-width="100"
          />
          <el-table-column
            prop="updateTime"
            label="更新时间"
            align="center"
            min-width="150"
          />
          <el-table-column
            label="操作"
            align="center"
            width="250"
            fixed="right"
          >
            <template slot-scope="scope">
              <el-button type="text" @click="handleSave(false, scope.row)">
                编辑
              </el-button>
              <!-- <el-button type="text" @click="handleEditStatus(scope.row)"> {{ scope.row.useStatus ? '禁用' : '启用' }} </el-button> -->
              <el-button type="text" @click="handleUserRole(scope.row)">
                分配角色
              </el-button>
              <el-button type="text" @click="handleDelete(scope.row)">
                删除
              </el-button>
              <el-button type="text" @click="handleUserAuth(scope.row)">
                分配权限
              </el-button>
            </template>
          </el-table-column>
        </el-table>
      </template>
      <template #footer>
        <el-pagination
          class="mt10"
          :page-size="page.size"
          :current-page="page.current"
          :total="total"
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
          layout="total, sizes, prev, pager, next, jumper"
        ></el-pagination>
      </template>
    </common-layout>
    <user-modal
      :userDialog.sync="userDialog"
      :userDetail="curRow"
      @close="closeModal"
    />
    <user-role-modal
      :rangeFlag="rangeFlag"
      :userRoleDialog.sync="userRoleDialog"
      :userDetail="curRow"
      @close="closeModal"
    />
    <user-auth-modal
      :rangeFlag="rangeFlag"
      :userAuthDialog.sync="userAuthDialog"
      :userDetail="curRow"
      @close="closeModal"
    />
  </div>
</template>
<script>
import api from "@/api/auth/user2";
import UserRoleModal from "./userRoleModal.vue";
import UserAuthModal from "./userAuthModal.vue";
import UserModal from "./userModal.vue";
export default {
  props: {
    rangeFlag: {
      require: true,
      type: String,
    },
  },
  components: { UserRoleModal, UserAuthModal, UserModal },
  data() {
    return {
      form: {},
      tableData: [],
      tableLoading: false,
      page: {
        current: 1,
        size: 20,
      },
      total: 0,
      userDialog: false,
      curRow: { useStatus: true },

      userRoleDialog: false,
      userAuthDialog: false,
    };
  },
  mounted() {
    this.query();
  },
  methods: {
    query() {
      let params = {
        ...this.form,
        ...this.page,
      };
      this.tableLoading = true;
      api
        .query(params)
        .then((res) => {
          this.tableData = res.data.records;
          this.total = res.data.total;
        })
        .finally(() => {
          this.tableLoading = false;
        });
    },
    handleQuery() {
      this.page.current = 1;
      this.query();
    },
    handleSizeChange(val) {
      this.page.size = val;
      this.page.current = 1;
      this.query();
    },
    handleCurrentChange(val) {
      this.page.current = val;
      this.query();
    },
    handleSave(isAdd, row) {
      this.curRow = isAdd ? { useStatus: true } : row;
      this.userDialog = true;
    },
    closeModal() {
      this.userDialog = false;
      this.userRoleDialog = false;
      this.showConfig = false;
      this.query();
    },
    // handleEditStatus(row) {
    //   this.$confirm(`确定${row.useStatus ? '禁用' : '启用'}此数据吗？`, '提示', {
    //     confirmButtonText: '确定',
    //     cancelButtonText: '取消',
    //     type: 'warning',
    //   }).then(() => {
    //     let params = _.cloneDeep(row)
    //     params.useStatus = !params.useStatus
    //     api.modify(params).then(() => {
    //       this.$msg.success('操作成功')
    //       this.query()
    //     })
    //   })
    // },
    handleUserRole(row) {
      this.curRow = _.cloneDeep(row);
      this.userRoleDialog = true;
    },
    handleUserAuth(row) {
      this.curRow = _.cloneDeep(row);
      this.userAuthDialog = true;
    },
    handleDelete(row) {
      this.$confirm("此操作将永久删除该数据, 是否继续?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).then(() => {
        api.delete({ id: row.id }).then(() => {
          this.$msg.success("操作成功");
          this.query();
        });
      });
    },
  },
};
</script>
