<template>
  <el-dialog
    title="分配角色"
    width="600px"
    :visible="userRoleDialog"
    @close="close"
    :close-on-click-modal="false"
  >
    <div style="display: flex; flex-wrap: wrap; justify-content: space-between">
      <template v-for="role in userRoleData">
        <el-checkbox
          :key="role.id"
          v-model="role.hasCheck"
          :disabled="!role.useStatus"
          class="role-layout"
        >
          {{ role.roleName }}
          <el-tag size="mini">{{ role.roleCode }}</el-tag>
        </el-checkbox>
      </template>
    </div>

    <div slot="footer">
      <el-button @click="close">取消</el-button>
      <el-button type="primary" @click="save">确定</el-button>
    </div>
  </el-dialog>
</template>
<script>
import api from "@/api/auth/user2";
export default {
  props: {
    userRoleDialog: {
      default: false,
      type: Boolean,
    },
    userDetail: {
      default: null,
      type: Object,
    },
    rangeFlag: {
      require: true,
      type: String,
    },
  },
  data() {
    return {
      userRoleData: [],
      includeRoleIds: [],
    };
  },
  watch: {
    userRoleDialog(val) {
      if (val) {
        if (this.userDetail && this.userDetail.id) {
          this.getUserRole();
        }
      }
    },
  },
  created() {},
  methods: {
    getUserRole() {
      api
        .getUserRole(this.rangeFlag, { userId: this.userDetail.id })
        .then((res) => {
          this.userRoleData = res.data;
        });
    },

    close() {
      this.$emit("update:userRoleDialog", false);
    },
    save() {
      const params = {
        userId: this.userDetail.id,
        includeRoleIds: [],
        excludeRoleIds: [],
      };
      this.userRoleData
        .filter((role) => role.useStatus)
        .forEach((role) => {
          if (role.hasCheck) {
            params.includeRoleIds.push(role.id);
          } else {
            params.excludeRoleIds.push(role.id);
          }
        });

      api.userRole(this.rangeFlag, params).then(() => {
        this.$msg.success("操作成功");
        this.close();
      });
    },
  },
};
</script>

<style scoped>
.role-layout {
  flex: 1 250px;
}
</style>
